.story {
  width: 75%;
  margin: 0 auto;
  box-shadow: 0 3rem 6rem rgba($color-black, 0.1);
  background-color: rgba($color-white, 0.6);
  border-radius: 0.3rem;
  padding: 6rem;
  padding-left: 9rem;
  font-size: $default-font-size;
  transform: skewX(-12deg);

  @include respond(tab-port) {
    width: 100%;
    padding: 4rem;
    padding-left: 7rem;
  }

  @include respond(phone) {
    transform: skewX(0);
  }

  &__shape {
    width: 15rem;
    height: 15rem;
    float: left;
    -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);

    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);

    transform: translateX(-3rem) skewX(12deg);
    position: relative;

    @include respond(phone) {
      transform: translateX(-3rem) skewX(0);
    }
  }

  &__image {
    height: 100%;
    transform: translateX(2rem) scale(1.4);
    /* backface-visibility: hidden; */
    transition: all 0.5s;
  }

  &__text {
    transform: skewX(12deg);

    @include respond(phone) {
      transform: skewX(0);
    }
  }

  &__caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
    color: $color-white;
    font-size: 1.7rem;
    text-transform: uppercase;
    text-align: center;
    opacity: 0;
    transition: all 0.5s;
    backface-visibility: hidden;
  }

  // &:hover &__caption {
  //   opacity: 1;
  //   transform: translate(-50%, -50%);
  // }

  // &:hover &__image {
  //   //transform: translateX(2rem) scale(1);
  //   transform: translateX(2rem);
  //   filter: blur(3px) brightness(80%);
  // }
}
