.header {
  position: relative;
  height: 95vh;
  background-image: linear-gradient(
    to right bottom,
    rgba($color-primary-light, 1),
    rgba($color-primary, 1)
  );
  background-size: cover;
  background-position: top;

  -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);

  @include respond(phone) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }

  &__logo-box {
    position: absolute;
    top: 4rem;
    left: 4rem;
  }

  &__logo {
    height: 8rem;
    transform: skewY(-2deg);
  }

  &__text-box {
    /* positioning on the complete middle! (top:50% - because of out clip path ledge optical change) */
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
