.feature-box {
  // background-color: rgba($color-white, 0.8);
  background-color: $color-white;
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 0.3rem;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
  transition: transform 0.3s;

  @include respond(tab-port) {
    padding: 2rem;
  }

  &__icon {
    font-size: 6rem;
  }

  &:hover {
    transform: translateY(-1.5rem) scale(1.02);
  }

  img {
    max-width: 100%;
    // max-width: 120px;
    height: 50%;
  }
}
