@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin respond-phone {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin respond-tab {
  @media (max-width: 1100px) {
    @content;
  }
}

/*
$breakpoints:
- phone 600px
- tab-port 900px 
- tab-land 1200px 
- big-desktop 1800px +
*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}
