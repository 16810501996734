.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "styles/abstracts/mixins";
@import "styles/abstracts/variables";

@import "styles/base/base";
@import "styles/base/animations";
@import "styles/base/typography";
@import "styles/base/utilities";

@import "styles/components/button";
@import "styles/components/composition";
@import "styles/components/feature-box";
@import "styles/components/card";
@import "styles/components/story";
@import "styles/components/bg-video";
@import "styles/components/form";
@import "styles/components/popup";

@import "styles/layout/header";
@import "styles/layout/grid";
@import "styles/layout/footer";
@import "styles/layout/navigation";

@import "styles/pages/home";