.footer {
  background-color: $color-grey-dark-3;
  padding: 10rem 0;
  font-size: 1.4rem;
  color: $color-grey-light-1;

  @include respond(tab-port) {
    padding: 8rem 0;
  }

  &__logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8rem;

    @include respond(tab-port) {
      margin-bottom: 6rem;
    }
  }

  &__logo {
    width: 10rem;
    height: auto;
    transform: skewY(-2deg);
  }

  &__navigation {
    border-top: 1px solid $color-grey-dark;
    padding-top: 2rem;
    display: inline-block;

    @include respond(tab-port) {
      width: 100%;
      text-align: center;
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__link {
    &:link,
    &:visited {
      color: $color-grey-light-1;
      background-color: $color-grey-dark-3;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      transition: all 0.2s;
    }

    &:hover,
    &:active {
      color: $color-primary;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.4);
      transform: rotate(5deg) scale(1.3);
    }
  }

  &__copyright {
    padding-top: 2rem;
    width: 80%;
    float: right;
    
    @include respond(tab-port) {
      width: 100%;
      float: none;
    }
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & > * {
      flex: 1;
      border-top: 1px solid $color-grey-dark;
    }
  }
}
