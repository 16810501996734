// COLORS
/* $color-primary: #55c57a;
$color-primary-light: #7ed56f;
$color-primary-dark: #28b485; */
$color-primary: #ff8100;
$color-primary-light: #ff9d39;
$color-primary-dark: #c66400;
$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;
$color-white: #fff;
$color-black: #000;
$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;
$color-secondary: #00b3ff;
$color-secondary-light: #09b5ff;
$color-secondary-dark: #007cb1;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// FONT
$default-font-size: 1.6rem;
