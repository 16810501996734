.section-about {
  background-color: $color-grey-light-1;
  padding: 25rem 0 35rem;
  margin-top: -20vh;

  @include respond(tab-port) {
    padding: 20rem 0;
  }
}

.section-features {
  padding: 20rem 0;
  background-image: linear-gradient(
    to right bottom,
    rgba($color-primary-light, 1),
    rgba($color-primary, 1)
  );

  transform: skewY(-6deg);
  margin-top: -10rem;
  & > * {
    transform: skewY(6deg);
  }

  @include respond(tab-port) {
    padding: 10rem 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.section-tours {
  background-color: $color-grey-light-1;
  padding: 25rem 0 15rem 0;
  margin-top: -10rem;

  @include respond(tab-port) {
    padding: 20rem 0 10rem 0;
  }
}

.section-stories {
  padding: 15rem 0;
  position: relative;

  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.section-book {
  padding: 15rem 0;
  background-image: linear-gradient(
    to right bottom,
    $color-primary-light,
    $color-primary
  );

  @include respond(tab-port) {
    padding: 10rem 0;
  }
}

.book {
  background-size: cover;
  border-radius: 0.3rem;
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
  background-image: linear-gradient(
      105deg,
      rgba($color-white, 0.9) 0%,
      rgba($color-white, 0.9) 50%,
      transparent 50%
    );
    // url(asset_path("nat-1-large.jpg"));

  @include respond(tab-land) {
    background-image: linear-gradient(
        105deg,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 65%,
        transparent 65%
      );
      // url(asset_path("nat-1-large.jpg"));
    background-size: cover;
  }

  @include respond(tab-port) {
    background-image: linear-gradient(
        to right,
        rgba($color-white, 0.9) 0%,
        rgba($color-white, 0.9) 100%
      );
      // url(asset_path("nat-1-large.jpg"));
  }

  &__form {
    width: 50%;
    padding: 6rem;

    @include respond(tab-land) {
      width: 65%;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}
